import { CircularProgress, Button, Dialog, MenuItem, Select } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import React, { useEffect, useState } from 'react';
import useFormData from 'src/hooks/useFormData';
import useSuperFetch from 'src/hooks/useSuperFetch';
import { styled } from '@mui/material/styles';
import useComponentRepository from 'src/hooks/useComponentRepository';
import Resource from 'src/vendor/Resource';
import ResourceSelector from 'src/components/Custom/ResourceSelector';

const StyledFormControl = styled(FormControl)(({theme}) =>({
    width: '100%'
}));

 function SelectFromTableField__old({
    label,
    name,
    field_component,
    resource
}) {
    const {formData, getValue, getMessages, setValue} = useFormData();
    const {getCustomComponentByKey} = useComponentRepository();
    const superFetch = useSuperFetch();
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    const FieldComponent = getCustomComponentByKey(field_component);

    function onRemove() {
        setValue(name, null);
    }

    function onOpenDialog() {
        setIsDialogOpen(true);
    }

    function onSelect(id) {
        setValue(name, id);
        setIsDialogOpen(false)
    }

    const DialogWrapper = (() => {
        if(isDialogOpen == false)
            return null;

        function SelectButton({onClick}) {

            return <Button onClick={onClick} size="small" variant="outlined">kiválaszt</Button>;
        }

        return (
                <Dialog maxWidth={`lg`} fullWidth={true} open={true} onClose={() => setIsDialogOpen(false)}>
                    <Resource name={resource} specialRowActions={[
                        {icon: 'fa-eye', label: 'Kijelölés', onClick: (row) => onSelect(row.id), component: SelectButton}
                    ]} />
                </Dialog>
        )
    })();
    
    return (
        <StyledFormControl error={getMessages(name).length > 0}>
            <InputLabel id={name} htmlFor={name}>{label}</InputLabel>
            <FieldComponent resourceId={getValue(name)} onRemove={onRemove} onOpenDialog={onOpenDialog} />
            {DialogWrapper}
            {getMessages(name).map((i,k) => <FormHelperText key={k} id={`${name}-error`}>{i.message}</FormHelperText>)}
        </StyledFormControl>
    )
}

export default function SelectFromTableField({
    label,
    name,
    field_component,
    resource,
    data,
    resourceId
}) {
    const {formData, getValue, getMessages, setValue} = useFormData();
    const {getCustomComponentByKey} = useComponentRepository();
    const [isDialogOpen, setIsDialogOpen] = useState(false);

    function onSelect(id) {
        setValue(name, id);
        setIsDialogOpen(false)
    }

    const selectorData = {};
    if(data) {
        Object.keys(data).forEach(function(key, index) {
            const i = data[key];
            if(i.indexOf(".") >= -1) {
                const parts = i.split(".");
    
                switch(parts[0]) {
                    case 'formData':
                        selectorData[key] = getValue(parts[1]);
                        return;
                    break;
                    case 'resource':
                        if(parts[1] == "resource_id") {
                            selectorData[key] = resourceId;
                            return;
                        }
                    break;
                }
            }
            selectorData[key] = i;
        });
    }

    
    return (
        <StyledFormControl error={getMessages(name).length > 0}>
            <ResourceSelector resource={resource} data={selectorData} currentValue={getValue(name)} onSelected={onSelect} onSelectionRemoved={() => onSelect(null)} />
            {getMessages(name).map((i,k) => <FormHelperText key={k} id={`${name}-error`}>{i.message}</FormHelperText>)}
        </StyledFormControl>
    )
}