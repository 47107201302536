import { Paper, Card, CardContent, Button, CardActions, Typography, Stack } from "@mui/material";
import { Box } from "@mui/system";
import { clone } from "lodash";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";

import { alpha, styled } from '@mui/material/styles';

import BarcodeReader from 'react-barcode-reader';
import useSuperFetch from "src/hooks/useSuperFetch";
import ResourceSelector from "../ResourceSelector";
import Resource from "src/vendor/Resource";

const InfoCard = styled(Card)(({ theme }) => ({
    border: '1px solid #fff',
    borderColor: alpha(theme.palette.grey[700], 0.2)
}))

const StyledStack = styled(Stack)(({ theme }) => ({

}))

const Item = styled(Paper)(({ theme }) => ({

        marginLeft: 5,
        marginRight: 5

}))

function UnpackShipment({resourceId: shipmentId, row}) {
    const orderId = row.order_id;
    const {post, get} = useSuperFetch();
    const [currentToteLocationId, setCurrentToteLocationId] = useState(null);
    const productsRef = useRef(null);
    const shipmentData = useMemo(async() => {
        const r = await get(`order_shipments/list?order_id=${orderId}`);
        console.log(r);
        return r.queryResult.rows;
    }, [orderId])

    console.log("Shipment data: ", shipmentData);

    useEffect(() => {}, [shipmentData]);

    useEffect(() => {
        if(productsRef && productsRef.current) {
            productsRef.current.refreshDataTable();
        }
    }, [currentToteLocationId]);

    function onScan(scan) {
        if(scan.indexOf("LOC-") === 0) {
            setCurrentToteLocationId(scan.split("-")[1]);
            return;
        }
    }


    async function doUnpack() {

        const t = await post('order_shipments/unpack', {
            order_id: orderId,
            tote_location_id: currentToteLocationId
        });
        if(productsRef && productsRef.current) {
            productsRef.current.refreshDataTable();
        }
    }


    return (
        <Box elevation={2}>
            <BarcodeReader onScan={onScan} minLength={5} />
            <StyledStack  spacing={2}>
                <Item>
                    <InfoCard>
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="div">
                            Nem átvett csomag kicsomagolása
                            </Typography>
                            <Typography variant="body2" color="text.secondary">
                                
                            </Typography>
                            <ResourceSelector resource={`locations`} currentValue={currentToteLocationId} onSelected={setCurrentToteLocationId} onSelectionRemoved={() => setCurrentToteLocationId(null)} />
                            {currentToteLocationId != null?<Resource ref={productsRef} name={`location_stocks`} data={{location_id: currentToteLocationId}} />:null}
                        </CardContent>
                        <CardActions>
                            <Button size="small" variant="contained" color="success" onClick={doUnpack}>Kicsomagol</Button>
                        </CardActions>
                    </InfoCard>
                </Item>

                <div>{JSON.stringify(shipmentData)}</div>
                

               
            </StyledStack>
        </Box>

    )
}

export default  UnpackShipment;