import { Popover, Typography } from "@mui/material";
import React from "react";
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
export default function({value, row}) {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handlePopoverOpen = (event) => {
        if(Object.hasOwn(row, 'stock_details') && row.stock_details)
            setAnchorEl(event.currentTarget);
    };
  
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };

    const popoverContent = (() => {
        if(!Object.hasOwn(row, 'stock_details') || row.stock_details == null)
            return null;

        const errs = Object.keys(row.stock_details).map((key) => {
            
            let value = row.stock_details[key];

            function getSubitems(array, key) {

                return (
                    <ul style={{marginLeft: '30px'}}>
                        {array.map(i => <li key={i[key]}>{i[key]}</li>) }
                    </ul>
                )
            }

            switch(key) {

                case 'isReadyToPack':
                    return value == 0?'A rendelés nincs csomagolásra előrejelezve.':null;
                case 'isPaidOrCod':
                    return value == 0?'A rendelés előre kell kifizetni, de még ez nem történt meg!':null;
                case 'isInRightStatus':
                    return value == 0?'A rendelés nincs olyan státuszban, hogy teljesíthető legyen.':null;
                case 'insufficientProducts':
                    return value.length?['Beszállítás szükséges, hogy teljesítsük a rendelést:', getSubitems(value, 'title')]:null;
                case 'errors':
                    return value.length?['Hibák merültek fel a rendelés teljesítése közben:', getSubitems(value, 'message')]:null;
            }

            return null;
        }).filter(i => i !== null)

        return (
            <div>
                <span>HIBÁK</span>
                <ul style={{marginLeft: '30px'}}>
                    {errs.map((i,k) => <li key={k} style={{listStyleType: 'disc'}}>{i}</li>)}
                </ul>
            </div>
        )
        /*return (
            <Typography sx={{ p: 1 }}>{JSON.stringify(row.stock_details)}</Typography>
        )*/
    })()

    const alertIcon = (() => {

        if(row?.stock_details && row.stock_details) {
            return <ReportProblemIcon size='small' color='warning' />
        }

        return null;
    })()
  
    const open = Boolean(anchorEl);
    return (
        <React.Fragment>
            <Typography
                aria-owns={open ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={handlePopoverOpen}
                onMouseLeave={handlePopoverClose}
            >
                {row.id} { alertIcon }
            </Typography>
            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                }}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
            >
                {popoverContent}
            </Popover>
        </React.Fragment>
    )

    return <img src={value} style={{width: 50, height: 50, borderRadius: '100%'}} />
}