import 'simplebar/src/simplebar.css';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import * as serviceWorker from './serviceWorker'
import AppProvider from './contexts/app'
import ComponentRepositoryProvider from './contexts/component-repository'
import DataGridEventsProvider from './contexts/datagrid-events'

import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import AppMessages from './components/AppMessages';

ReactDOM.render(
  <HelmetProvider>
    <BrowserRouter>
        <AppProvider>
          
          <DataGridEventsProvider>
              <ComponentRepositoryProvider>
                  <App />
                  <AppMessages />
              </ComponentRepositoryProvider>
          </DataGridEventsProvider>
        </AppProvider>
    </BrowserRouter>
  </HelmetProvider>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
