import { Grid, Box,Avatar,Paper } from "@mui/material";
import { Outlet } from "react-router-dom";
import Scrollbar from "src/components/Scrollbar";

export default function AuthLayout({children}) {

    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
            <Grid
                item
                xs={false}
                sm={4}
                md={12}
                sx={{
                    backgroundImage: 'url(https://source.unsplash.com/random)',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) => t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }} />
            <Grid item xs={12} sm={8} md={4} sx={{
                'position': 'fixed',
                'left': {'xs': '0%', 'md': '25%'},
                'top': {'xs': '0%', 'md': '10%'},
                'minWidth': {'xs': '100%', 'md': '50%'},
                'minHeight': {'xs': '100%', 'md': '80%'},
                'borderRadius': '50px'
            }} component={Paper} elevation={0} square>
                
                <Scrollbar
                    sx={{
                    height: 1,
                    '& .simplebar-content': { height: 1, display: 'flex', flexDirection: 'column' }
                    }}
                >
                    <Box
                    sx={{
                        my: 8,
                        mx: 4,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        alignSelf: 'stretch'
                    }}>
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                
                            </Avatar>
                            <Outlet />
                    </Box>
                </Scrollbar>
            </Grid>
        </Grid>
    )
}