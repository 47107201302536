import {useEffect, useState} from 'react'
import FormXContext from "./Context";

import { Alert, Box, Snackbar } from '@mui/material';
import useSuperFetch from 'src/hooks/useSuperFetch';

import {forwardRef, useImperativeHandle, useRef} from 'react';

export default forwardRef(({action, initialValues = {}, data = {}, customFns = {}, onSuccess = null, onSubmit = null, onChange=null, onError = null, onResponse = null, children}, ref) => {
    const [formData, setFormData] = useState({
        initialValues: initialValues??{},
        values: {},
        messages: [],
        isSubmitting: false,
        hasSubmitted: false,
        isSuccess: false,
        successMessage: null
    });

    useImperativeHandle(ref, () => ({
        setValues(vals) {
            setFormData({...formData, values: vals})
        }
    }));


    const superFetch = useSuperFetch();

    useEffect(() => {
        if(onChange) {
            onChange({...formData.initialValues, ...formData.values});
        }
    }, [formData.values])
    const handleSubmit = async (e) => {
        if(e) {
            e.preventDefault();
        }
        await setFormData({
            ...formData,
            isSubmitting: true
        })
        
        if(action == null) {
            onSubmit({...formData.initialValues, ...formData.values});
            await setFormData({
                ...formData,
                isSubmitting: false
            })

            return;
        }

        const response = await superFetch.post(action+(Object.keys(data).length?'?&'+superFetch.serialize(data):''), {
            ...data,
            ...formData.initialValues,
            ...formData.values
        }, true);

        await setFormData({
            ...formData,
            messages: response?.messages || []
        });
        if(onResponse) {
            await onResponse({formData, result: response});
        }
        if(response.messages.length == 0 && onSuccess) {
            await onSuccess({formData, result: response});
        }

        if(response.messages.length > 0 && onError) {
            await onError({formData, result: response})
        }

        await setFormData({
            ...formData,
            isSubmitting: false,
            messages: response?.messages || [],
            isSuccess: response?.messages?.length == 0,
            successMessage: response?.successMessage ? response.successMessage : null
        })
    };

    const clearForm = () => {
        setFormData({
            ...formData,
            values: formData.initialValues
        });
    }

    const getValue = (key) => {
        let v = '';
        if(formData.initialValues.hasOwnProperty(key) ) {
            v = formData.initialValues[key];
        }
        if(formData.values.hasOwnProperty(key)) {
            v = formData.values[key];
        }

        return v;
    }

    const onValueChanged = (e) => {
        const { name, value, files } = e.target;
        setFormData({
            ...formData,
            values: {
                ...formData.values,
                [name]: (files && files.length > 0)?files[0]:value
            }
        })
    }

    const setValue = (key, value) => {
        setFormData({
            ...formData,
            values: {
                ...formData.values,
                [key]: value
            }
        })
    }

    const getMessages = (key) => {
        const messages = formData.messages.filter((k) => k?.field == key);

        return messages;
    }

    const generalErrors = (() => {

        const commonErrors = formData.messages.filter((k) => typeof k == "string");

        if(commonErrors.length == 0)
            return null;

        return commonErrors.map(err => (
            <Alert key={err} severity="error">{err}</Alert>
        ))
    })();

    const successSnackbar = (() => {
        if(formData.isSuccess) {
            return (
                <Snackbar open={formData.isSuccess} autoHideDuration={6000} anchorOrigin={{vertical: 'top', horizontal: 'center'}} onClose={() => {
                    setFormData({...formData, isSuccess: false, successMessage: null})
                }}>
                    <Alert severity="success" sx={{ width: '100%' }}>
                        {formData.successMessage?formData.successMessage:'Sikeres mentés!'}
                    </Alert>
                </Snackbar>
            )
        }
    })()
    

    const contextData = {
        formData,
        setFormData,
        handleSubmit,
        onSubmit: handleSubmit,
        clearForm,
        getValue,
        onValueChanged,
        setValue,
        getMessages,
        customFns
    };

    return <FormXContext.Provider value={contextData}>
        <Box
            component="form"
            sx={{
                '& > :not(style)': { m: 1 },
                width: '100%'
            }}
            noValidate
            autoComplete="off"
            onSubmit={handleSubmit}
        >
            {successSnackbar}
            {generalErrors}
            {children}
        </Box>
    </FormXContext.Provider>
});