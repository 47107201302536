import { useLocation, useParams } from "react-router-dom";
import { CircularProgress, Paper } from "@mui/material";
import Page from "src/components/Page";
import Resource from "src/vendor/Resource";
import { useEffect, useState } from "react";
import useSuperFetch from "src/hooks/useSuperFetch";

import { styled } from '@mui/material/styles';
import { Form } from "src/vendor/FormX";
import RemoteForm from "src/vendor/FormX/RemoteForm";

export default function() {
    const { productid } = useParams();
    const [resourceData, setResourceData] = useState(null);

    const fetch = useSuperFetch();
    
    useEffect(() => {
        (async () => {
            const r = await fetch.get(`my_products/view/${productid}`);
            setResourceData(r.data);
        })()
    }, [productid])

    if(resourceData == null)
        return null;

    return <Page title={'Termék szerkesztés'}>
        <RemoteForm
            resource={'my_products'}
            remoteFormKey={'edit'}
            resourceId={productid}
            defaultValues={resourceData}
        />
    </Page>
}