


import React, { useContext, useState } from 'react';




export function getInitialState() {
    return {
        'printLocationBarcode': async function({row, action, superFetch}) {

            await superFetch.post('print_queue/add', {
                type: 'location',
                params: ['LOC-'+row.id, row.name]
            })
        },
        'printProductBarcode': async function({row, action, superFetch}) {

            await superFetch.post('print_queue/add', {
                type: 'location',
                params: ['LOC-'+row.id, row.name]
            })
        },
        'deleteRecord': async function({row, resource, action, superFetch, data, refresh}) {
            await superFetch.get(`${resource}/delete/${row.id}?${superFetch.serialize(data)}`);
            refresh();
        },
        'CALL_WITH_RESOURCE_ID': async function({row, resource, action, superFetch, data, refresh, datatable: {setLoading} }) {
            //null,null,null,{}
            setLoading(true);
            if(data?.data) {
                data.data.resource_id = row.id;
                await superFetch.post(data.action, data.data)
            } else {
                await superFetch.get(data.action+"?resource_id="+row.id);
            }
            //setLoading(false);
            refresh();
        },
        'CALL_URL': async function({row, resource, action, superFetch, data, refresh, datatable: {setLoading} }) {
            setLoading(true);
            if(data?.data) {
                await superFetch.post(data.action, data.data)
            } else {
                await superFetch.get(data.action);
            }
            //setLoading(false);
            refresh();
        },
        'DOWNLOAD_URL': async function({row, resource, action, superFetch, data, refresh, datatable: {setLoading} }) {

            /**
             * const selectedRows = data.selectionModel
             * 
             */

            const selection = data.selectionModel;
            await superFetch.download(data.action, {datagrid_selection: selection});
            //setLoading(false);

        },
        'CALL_URL_WITH_SELECTION': async function({row, resource, action, superFetch, data, refresh, datatable: {setLoading} }) {
            /**
             * const selectedRows = data.selectionModel
             * 
             */
            setLoading(true);
            const selection = data.selectionModel;
            await superFetch.get(data.action, {datagrid_selection: selection});
            refresh();
            setLoading(false);

        },
        'REFRESH_DT': async function({row, resource, action, superFetch, data, refresh, datatable: {setLoading} }) {
            /**
             * const selectedRows = data.selectionModel
             * 
             */
            setLoading(true);
            refresh();
            setLoading(false);

        },
    };
}
