import Page from "src/components/Page";
import BarcodeReader from 'react-barcode-reader'
import useSuperFetch from "src/hooks/useSuperFetch";
import { useEffect, useState } from "react";
import { Card, Paper, Stack } from "@mui/material";
import { alpha, styled } from '@mui/material/styles';

import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

const InfoCard = styled(Card)(({ theme }) => ({
    border: '1px solid #fff',
    borderColor: alpha(theme.palette.grey[700], 0.2)
}))

const StyledStack = styled(Stack)(({ theme }) => ({

}))

const Item = styled(Paper)(({ theme }) => ({

        marginLeft: 5,
        marginRight: 5

}))

export default function () {
    const [cards, setCards] = useState([]);
    const {get} = useSuperFetch();


    useEffect(async () => {
        const r = await get('dashboard/stats');

        console.log(r.data.cards);
        setCards(r.data.cards);
    }, [])

    if(cards.length == 0)
        return null;

        function test() {
            Packeta.Widget.pick("8c70789655d7c2de", () => {}, null, null)
        }

    return <Page title={'Vezérlőpult'}>
        <StyledStack direction={'row'}>
            {cards.map((i,k) => <Item key={k}>
                <InfoCard>
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                        {i.param1} {i.unit}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                        {i.title}
                        </Typography>
                    </CardContent>
                </InfoCard>
            </Item>)}
        </StyledStack>
        
        <Button onClick={test}>Test</Button>
    </Page>
}