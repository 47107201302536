export function getInitialState() {
    return {
        showSidebar: true,
        sidebarUnfoldable: false,
        tokenId: window.localStorage.getItem('token_id'),
        title: '',
        userData: {
            fullName: ''
        },
        errorMessages: {},
        messages: []
    };
}
