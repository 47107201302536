import { Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import useSuperFetch from "src/hooks/useSuperFetch";
import SubmitButton from "./Buttons/SubmitButton";
import Form from "./Form";
import FormGroup from "./FormGroup";
import TextField from "./Inputs/TextField";
import Checkbox from "./Inputs/Checkbox";
import SelectField from "./Inputs/SelectField";
import FileField from "./Inputs/FileField";
import ResourceTable from "./Inputs/ResourceTableField";
import SelectFromTableField from "./Inputs/SelectFromTableField";
import Conditional_ProductHasLot from "./Inputs/Conditional_ProductHasLot";
import Order_ReadyToPackField from "./Inputs/Order_ReadyToPackField";
import Event_Button from "./Inputs/Event_Button";
import DraftWYSIWYGField from "./Inputs/DraftWYSIWYGField";
import TextareaField from "./Inputs/TextareaField";

const getFormInputByKey = (key) => {
    switch(key) {
        case 'Phalcon\\Forms\\Element\\Text':
            return TextField;
        case 'Phalcon\\Forms\\Element\\Check':
            return Checkbox;
        
        case 'ShipNow\\Forms\\Elements\\OrderReadyToPack':
        case 'Hermes\\Forms\\Elements\\OrderReadyToPack':
            return Order_ReadyToPackField;
        
        case 'ShipNow\\Forms\\Elements\\EventButton':
        case 'Hermes\\Forms\\Elements\\EventButton':
            return Event_Button;

        case 'Phalcon\\Forms\\Element\\Select':
            return SelectField;
        case 'Phalcon\\Forms\\Element\\File':
            return FileField;

        case 'ShipNow\\Forms\\Elements\\ResourceTable':
        case 'Hermes\\Forms\\Elements\\ResourceTable':
            return ResourceTable;

        case 'ShipNow\\Forms\\Elements\\SelectFromTable':
        case 'Hermes\\Forms\\Elements\\SelectFromTable':
            return SelectFromTableField;

        case 'ShipNow\\Forms\\Elements\\DraftWYSIWYG':
        case 'Hermes\\Forms\\Elements\\DraftWYSIWYG':
            return DraftWYSIWYGField;



        case 'ShipNow\\Forms\\Elements\\Textarea':
        case 'Hermes\\Forms\\Elements\\Textarea':
            return TextareaField;


        default:
            return null
    }
}

const getWrapperElement = ({resource, resourceId, conditional = null}) => {
    if(conditional) {

        switch(conditional.type) {
            case 'Condition.ProductHasLot':
                return ({children}) => <Conditional_ProductHasLot {...{resource, resourceId}} productId_key={conditional.productId_key}>{children}</Conditional_ProductHasLot>
            break;
        }
    }
    return React.Fragment;
}

export default function RemoteForm({resource, remoteFormKey, resourceId, defaultValues = {}, onSubmit, onError, onSuccess, data = {}, customFormAction = null}) {
    const superFetch = useSuperFetch();

    const [remoteFormMap, setRemoteFormMap] = useState(null);


    async function fetchRemoteForm() {
        const r = await superFetch.get(`${resource}/forms?action=${remoteFormKey}&resource_id=${resourceId}`);
        setRemoteFormMap(r.form);
    }

    useEffect(() => {
        fetchRemoteForm();
    }, [])

    if(remoteFormMap == null)
        return null;

    const groups = [...new Set(remoteFormMap.fields.map(item => item.group))].map((groupName) => {

        let gridCell = {
            xs: 12,
            md: 6,
            lg: 3
        };
        if(remoteFormMap.fields.filter(i => i.group == groupName).filter(i => getFormInputByKey(i.type) != null).length == 1) {
            gridCell = {
                xs: 12
            }
        }

        const groupInputs = remoteFormMap.fields.filter(i => i.group == groupName).map(input => {
            const FormInputElement = getFormInputByKey(input.type);
            if(FormInputElement == null)
                return null;

            const WrapperElement = getWrapperElement({resource, resourceId, ...input.options});
            return ( 
                <Grid item {...gridCell}  key={input.key}>
                    <WrapperElement>
                        <FormInputElement {...{resource, resourceId}} {...input.options}  name={input.key} label={input.label} />
                    </WrapperElement>
                </Grid>
            )
        })

        if(groupInputs.filter(i => i != null).length == 0)
            return null;

        return (
            <FormGroup key={groupName} label={groupName??"Alap adatok"}>
                <Grid container  spacing={2}>
                    {groupInputs}
                </Grid>
            </FormGroup>
        )
    })

    const baseAction = `${resource}/${remoteFormKey}`;

    const formAction = `${baseAction}${resourceId? `/${resourceId}`:'' }`;

    return (
        <Form action={customFormAction?customFormAction:formAction} data={data} customFns={{fetchRemoteForm}} onSuccess={onSuccess} onError={onError} onSubmit={onSubmit} key={remoteFormKey} initialValues={defaultValues}>
            {groups}
            <SubmitButton label={'Rendben'} />
        </Form>
    )
}