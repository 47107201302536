import { Navigate, Outlet, useRoutes } from 'react-router-dom';
import DashboardLayout from './layouts/dashboard';
// layouts
import DefaultLayout from './layouts/default';
import AuthLayout from './layouts/auth';
//
import Login from './pages/authentication/Login';
import Signup from './pages/authentication/Signup';
import DashboardApp from './pages/dashboard';
import FormXTest from './pages/sandbox/FormXTest';
import GuardedRoute from './helpers/GuardedRoute';
import UserWithoutClient from './pages/authentication/UserWithoutClient';
import GenericResource from './pages/generic-resource';
import Utilities from './pages/utilities';
import GenericResourceV2 from './pages/generic-resource-v2';
import QuickEdit from './pages/products/quick-edit';

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: '/app',
      element: <GuardedRoute element={<DashboardLayout />} />,
      children: [
        { path: 'dashboard', element: <DashboardApp /> },
        { path: 'clients', element: <Outlet />, children: [
            
            { path: 'user_without_client', element: <span>No client for this user</span> }
          ]
        },
        { path: 'my_products', element: <Outlet />, children: [
            
            { path: 'quick_edit/:productid', element: <QuickEdit /> }
          ]
        },
        { path: 'generic-resource', element: <GenericResource /> },
        { path: 'generic-resource-v2/:resource', element: <GenericResourceV2 /> },
        { path: 'utilities', element: <Utilities /> },

        { path: 'sandbox', element: <Outlet />, children: [
            { path: 'formx', element: <FormXTest /> },
          ]
        },
        

      ]
    },
    {
      path: '/',
      element: <AuthLayout />,
      children: [
        { path: '/', element: <Navigate to="/app/dashboard" /> },
        { path: 'login', element: <Login /> },
        { path: 'signup', element: <Signup /> },
        { path: 'user_without_client', element: <UserWithoutClient /> }
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}