import { useState, useEffect } from "react";
import useSuperFetch from "./useSuperFetch";

export default function useListingQuery({url, noLoadingState = false, data = {}, page = 0, limit = 100, onRefresh} = options) {
    const superFetch = useSuperFetch();

    const [fetchData, setFetchData] = useState({
        url,
        data,
        page,
        limit,
        filters: {},
        sorting: {}
    })

    const [queryData, setQueryData] = useState({
        isLoading: true,
        data: [],
        totalRecords: 0,
        totalPages: 0,
        limit,
        page: 0
    });

    function serialize(obj, prefix) {
        var str = [],
          p;
        for (p in obj) {
          if (obj.hasOwnProperty(p)) {
            var k = prefix ? prefix + "[" + p + "]" : p,
              v = obj[p];
            str.push((v !== null && typeof v === "object") ?
              serialize(v, k) :
              encodeURIComponent(k) + "=" + encodeURIComponent(v));
          }
        }
        return str.join("&");
      }

    async function fetchRows(_dat = {}) {
        if(queryData.isLoading == false) {
            if(noLoadingState == false)
                setQueryData({...queryData, isLoading: true});
        }
        const r = await superFetch.get(fetchData.url+"?"+serialize({filters: fetchData.filters})+"&"+serialize({sorting: fetchData.sorting})+"&page="+fetchData.page+"&limit="+fetchData.limit+"&"+serialize({...fetchData.data, ..._dat}), fetchData.data);
            
        setQueryData({
            ...queryData, 
            isLoading: false,
            data: r.queryResult.rows,
            totalRecords: parseInt(r.queryResult.totalRows),
            limit: parseInt(r.queryResult.limit)
        });
    }

    useEffect(() => {
        try {

            //setQueryData({...queryData, isLoading: true});
            fetchRows();
        } catch(e) {
            console.error("useListingQuery failed useEffect with: ", e);
        }
    }, [fetchData.page, fetchData.limit, JSON.stringify(fetchData.filters), JSON.stringify(fetchData.sorting)])
    /*useEffect(() => {
        setQueryData({...queryData, isLoading: true});
        fetchRows();
    }, []);

    useEffect(() => {
        setQueryData({...queryData, isLoading: true});
        fetchRows();
    }, [fetchData.page])

    useEffect(() => {
        setQueryData({...queryData, isLoading: true});
        fetchRows();
    }, [JSON.stringify(fetchData.filters)])*/

    return {
        ...queryData,
        refresh: async (_dat = {}) => {if(onRefresh) {await onRefresh();} await fetchRows(_dat)},
        setPage: (p) => setFetchData({...fetchData, page: p}),
        setFilters: (f) => setFetchData({...fetchData, filters: f}),
        setSorting: (s) => setFetchData({...fetchData, sorting: {field: s[0].field, order: s[0].sort} }),
        setLimit: (l) => setFetchData({...fetchData, limit: l})
    };
}