import CreateDrawer from "src/vendor/Resource/TableView/Drawers/CreateDrawer";
import EditDrawer from "src/vendor/Resource/TableView/Drawers/EditDrawer";


import React, { useContext, useState } from 'react';
import ImportDialog from "src/vendor/Resource/TableView/Dialogs/ImportDialog";
import RemoteForm from "src/vendor/FormX/RemoteForm";
import DeliveryIntakeView from "src/components/Custom/Delivery/Intake";
import { Dialog } from "@mui/material";
import PromptDialogWithEvent from "src/vendor/Resource/TableView/Dialogs/PromptDialogWithEvent";
import AppErrorAlert from "src/components/AppErrorAlert";
import Details from "src/components/Custom/Order/Details";
import UnpackShipment from "src/components/Custom/Shipment/Unpack";
import ResetShipment from "src/components/Custom/Shipment/Reset";
import SwitchShipmentCourier from "src/components/Custom/Shipment/SwitchCourier";
import Checking from "src/components/Custom/Delivery/Checking";
import FormDrawer from "src/vendor/Resource/TableView/Drawers/FormDrawer";

function DemoComp() {
    return <p>DEMO COMP</p>
}


function CustomRemoteForm({resource, resourceId, data, remoteFormKey, customFormAction}) {
    return <React.Fragment>
        
        <RemoteForm data={data} resource={resource} resourceId={resourceId} remoteFormKey={remoteFormKey} customFormAction={customFormAction}></RemoteForm>
    </React.Fragment>
}

function ProductSelectionField({resourceId, onOpenDialog, onRemove}) {

    if(isNaN(parseInt(resourceId))) {
        return (<p>No product selected <span onClick={onOpenDialog}>search</span></p>)
    }

    return (<p>Product selected: {resourceId}, changed your mind? <span onClick={onRemove}>remove</span></p>)
}


export function getInitialState() {
    return {
        'DEMO': DemoComp,
        'TableView.Import': ImportDialog,
        'TableView.DrawerCreate': CreateDrawer,
        'TableView.DrawerEdit': EditDrawer,
        'TableView.DrawerForm': FormDrawer,
        'TableView.OrderView': CustomRemoteForm,
        'CustomRemoteForm': CustomRemoteForm,
        'ProductSelection_Field': ProductSelectionField,
        'Delivery.InTake': DeliveryIntakeView,
        'Delivery.Checking': Checking,
        'PromptDialogWithEvent': PromptDialogWithEvent,
        'Orders.Details': Details,
        'Shipments.Unpack': UnpackShipment,
        'Shipments.Reset': ResetShipment,
        'Shipments.SwitchCourier': SwitchShipmentCourier
    };
}
