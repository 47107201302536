import { useEffect, useReducer, useState } from "react";
import AppContext from "./context";
import actions from "./actions";
import { getInitialState } from "./getInitialState";
import { useNavigate } from "react-router-dom";
import { getCharByKeyCode } from "./charcodes";


function serialize(obj, prefix) {
    var str = [],
      p;
    for (p in obj) {
      if (obj.hasOwnProperty(p)) {
        var k = prefix ? prefix + "[" + p + "]" : p,
          v = obj[p];
        str.push((v !== null && typeof v === "object") ?
          serialize(v, k) :
          encodeURIComponent(k) + "=" + encodeURIComponent(v));
      }
    }
    return str.join("&");
};

function urlencodeFormData(fd){
    var s = '';
    function encode(s){ return encodeURIComponent(s).replace(/%20/g,'+'); }
    for(var pair of fd.entries()){
        if(typeof pair[1]=='string'){
            s += (s?'&':'') + encode(pair[0])+'='+encode(pair[1]);
        }
    }
    return s;
}

async function base({method, navigate, dispatch, headers, isDownload = false}, url, data = {}, customErrorHandler = false) {
    const formData = new FormData();

    for ( const key in data ) {
        if((method == "get") && (data[key] == null)) {

        } else {
            formData.append(key, data[key]);
        }
    }

    const getVars = (method=="get")?urlencodeFormData(formData):"1=1";

    const concatonation = url.indexOf("?") > -1?'&':'?';

    const response = await fetch(`${process.env.REACT_APP_API_URL}${url}${concatonation}${getVars}`, {
        method,
        headers,
        body: method=="get"?null:formData
    });
    switch(response.status) {
        case 405:
        case 403:
            dispatch({type: 'SET_TOKEN', payload: null});
            window.location = '/';
        break;

        case 200:
            if(isDownload) {
                const responseBlob = await response.blob();
                const blobContent = await responseBlob.text();

                try {
                    const result = JSON.parse(blobContent)
                    if(result?.messages && result.messages.length > 0) {
                        //dispatch({type: 'ADD_ERROR_MESSAGES', payload: {errorMessageKey, messages: result.messages} });
                        if(customErrorHandler == false) {
                            result.messages.map(msg => dispatch({type: 'ADD_MESSAGE', payload: {severity: 'error', message: msg}}))
                        }
                    }
                    return;
                } catch(ex) {}
                const blob = new Blob([responseBlob], {type: responseBlob.type})
                //blob.type = 'octet/stream'
                //var blob2 = new Blob([blob], {type: "octet/stream"});
                var file = window.URL.createObjectURL(blob);
                /*console.log(blob, file);
                return;*/
                window.location.assign(file);
                return;
            }
            const result = await response.json();
            if(result?.token && (result.token === null || result.token.length > 20)) {
                dispatch({type: 'SET_TOKEN', payload: result.token});
            }
            if(result?.redirect) {
                navigate(result.redirect, {replace: true})

                return null;
            }

            if(result?.messages && result.messages.length > 0) {
                //dispatch({type: 'ADD_ERROR_MESSAGES', payload: {errorMessageKey, messages: result.messages} });
                if(customErrorHandler == false) {
                    result.messages.map(msg => dispatch({type: 'ADD_MESSAGE', payload: {severity: 'error', message: msg}}))
                }
            }

            if(result?.success_messages && result.success_messages.length > 0) {
                //dispatch({type: 'ADD_ERROR_MESSAGES', payload: {errorMessageKey, messages: result.success_messages} });
                if(customErrorHandler == false) {
                    result.success_messages.map(msg => dispatch({type: 'ADD_MESSAGE', payload: {severity: 'success', message: msg}}))
                }
            }

            return result;
        default:
            console.log("Unknown result status: ", response.status);
            return null;
    }
}

export default function({children}) {
    const [state, dispatch] = useReducer(actions, getInitialState())
    const navigate = useNavigate();

    const customHeader = () => ({
        Accept: 'application/json',
        Authorization: 'Bearer ' + state.tokenId || undefined,
    });

    const superFetch = {};

    ['get', 'post', 'put', 'delete'].forEach(method => {
        superFetch[method] = base.bind(null, {method, navigate, dispatch, headers: customHeader()});
    });

    superFetch.download = base.bind(null, {method: 'get', navigate, dispatch, headers: customHeader(), isDownload: true});

    superFetch.serialize = serialize;

    useEffect(() => {
        if(state.tokenId === null)
            return;
            
        (async () => {
            const resp = await superFetch.get('authentication/get_details');
            dispatch({type: 'UPDATE_USER_DATA', payload: resp.data})
        })()
        
    }, [])


    return <AppContext.Provider value={{
        ...state,
        superFetch,
        dispatch
    }}>
        {children}
    </AppContext.Provider>
}