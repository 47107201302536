import { Alert, Snackbar } from "@mui/material";
import { useEffect } from "react";
import useAppState from "src/hooks/useAppState";

function Snack({message, severity, handleClose}) {
    const vertical = 'top';
    const horizontal = 'center';
    const open = true;

    return (
        <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        onClose={handleClose}
        key={vertical + horizontal}
        >
            <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    )
}
window.snackbarTimers = {};

export default function() {

    const {messages, dispatch} = useAppState();

    useEffect(() => {
        messages.map(i => {
            if(Object.keys(snackbarTimers).indexOf(i.uid) > -1) 
                return;

            snackbarTimers[i.uid] = setTimeout(() => {
                dispatch({type: 'REMOVE_MESSAGE', payload: i.uid})
            }, 5000);
        })
    }, [messages])

    const msgs = messages.map(i => <Snack key={i.uid} severity={i.severity} message={i.message} handleClose={() => dispatch({type: 'REMOVE_MESSAGE', payload: i.uid})}/>)

    return msgs;
}